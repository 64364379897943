@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.title {
  color: $text-primary-color;
}

.title,
.text {
  text-transform: uppercase;
  font-weight: 500;
  @include font-lg;
}

.text {
  font-weight: 400;
  color: $subtext-color;
  @include font-base;

  &_small {
    font-weight: 400;
    text-transform: none;
  }

  span {
    text-transform: none;
  }
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input {
  label,
  input {
    color: $input-text-color;

    &::placeholder {
      color: $input-placeholder-color;
    }
  }

  label {
    color: $text-primary-color;
    font-weight: 500;
  }
}

.input {
  input,
  textarea {
    border: 2px solid $secondary-color;
  }
}

.input[class^="CustomSelect_field"]:not([class*="error"]) {
  border: 2px solid $secondary-color;
}

@include mobile-lg {
  .inputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &_dob {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
