@import "../../../styles/variables.scss";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.content {
  width: 100%;
  max-width: $laptop-width;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 50px 40px 40px;
  box-sizing: border-box;
}

.children {
  max-width: 100%;
  width: 100%;
  flex: 1;
}
