@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: $footer-background;
  color: $footer-text;
  flex-direction: column;
  align-items: center;
}

.address,
.email {
  text-transform: uppercase;
  color: $footer-text;
  font-weight: 600;
  text-align: center;
}

.telephone {
  text-transform: uppercase;
  color: $footer-text;
  font-weight: 600;
  font-size: 20px;
}

@include mobile-md {
  .container {
    flex-direction: row;
    align-items: center;
    padding: 0 40px;
  }

  .address {
    text-align: left;
  }
}
