@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.icon {
  @include transition(fill);

  path,
  rect {
    fill: $svg-icons-primary;
    @include transition(fill);
  }
}
