@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.title {
  text-transform: uppercase;
  font-weight: 300;
  color: $text-primary-color;
  @include font-2xl;
}

.results {
  display: grid;
  gap: 20px;
}

.item {
  background: $section-background-color;
  box-shadow: 1px 1px 4px 3px $shadow-color;
}

.image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: bottom;
}

.bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 26px 20px;
  border-top: 4px solid $delimitter_line_color;
  box-sizing: border-box;
}

.name {
  color: $text-primary-color;
  text-transform: uppercase;
  font-weight: 600;
  @include font-xl;
}

.details {
  @include font-lg;

  tr {
    td:first-of-type {
      color: $text-primary-color;
      padding-right: 20px;
      font-weight: 600;
    }

    td:last-of-type {
      color: $subtext-color;
    }
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 26px;
  box-sizing: border-box;
}

.price {
  display: flex;
  flex-direction: column;

  &_from {
    color: $subtext-color;
    @include font-lg;
  }

  &_value {
    font-weight: 600;
    color: $text-primary-color;
    @include font-xl;
  }
}

.button {
  width: unset;
  padding: 0 40px;
}

@include tablet {
  .title {
    @include font-4xl;
  }

  .results {
    grid-template-columns: repeat(2, 1fr);
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .name {
    @include font-2xl;
  }

  .button {
    padding: 0 80px;
  }
}
