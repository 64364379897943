@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.container {
  flex-direction: column;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  cursor: pointer;
  padding: 10px;
  box-sizing: border-box;
  background-color: $element-background-primary;
  border: 2px solid $element-background-secondary;
  @include transition(background-color);

  &_disabled {
    cursor: not-allowed;
    opacity: 0.5;
    color: $subtext-color;

    &:hover {
      background-color: $element-background-primary;
    }
  }
}

.priceInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1;
}

.title {
  text-transform: uppercase;
  color: $subtext-color;
  @include font-2xl;
}

.price {
  text-transform: uppercase;
  font-weight: 700;
  color: $subtext-color;
  @include font-2xl;
}

.checkboxContainer {
  height: 100%;
}

@include mobile-lg {
  .container {
    flex-direction: row;
  }
}
