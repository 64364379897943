@import "../../../styles/variables.scss";

.flatpickr-months {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .flatpickr-prev-month,
  .flatpickr-next-month {
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flatpickr-month {
    height: 46px;
  }
}

.flatpickr-current-month {
  height: 100%;
  padding: 10px 0;
}

.flatpickr-day.selected,
.flatpickr-monthSelect-month.selected,
.flatpickr-monthSelect-month.startRange,
.flatpickr-monthSelect-month.endRange {
  background: $primary-color !important;
  border-color: $primary-color !important;
}

.flatpickr-monthSelect-month.flatpickr-disabled {
  color: $secondary-color !important;
}
